import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="privacy-policy">
      <div className="container">
        <h1>Privacy Policy</h1>
        <h2>USER PRIVACY STATEMENT</h2>
        <p>
          <strong>Effective Date: 1st June 2020</strong>
        </p>
        <p>
          Move collects information about you when you use our mobile
          applications, websites, and other online products and services to
          interact with transportation solutions and or such other opportunities
          that are within Moves mandate (collectively, the “Services”) and
          through other interactions and communications you have with us.
        </p>
        <h4>Scope and Application</h4>
        <p>
          This Privacy Statement (“Statement”) applies to persons anywhere in
          the Pakistan who use our apps or Services (“Users”). This Statement
          does not apply to information we collect from or about vehicle-owners,
          drivers, couriers, partner transportation companies, or any other
          persons who use the Move platform at the suppliers-end (collectively
          “Drivers”).
        </p>
        <h3>Collection of Information</h3>
        <h4>Information You Provide to Us</h4>
        <p>
          We collect information you provide directly to us, such as when you
          create or modify your account, request services, contact customer
          support, or otherwise communicate with us. This information may
          include: name, email, phone number, postal address, profile picture,
          payment method, and other information you choose to provide.
        </p>
        <h4>Information We Collect Through Your Use of Our Services</h4>
        <p>
          When you use our Services, we collect information about you in the
          following general categories:
        </p>

        <p>
          Location Information: When you use the Services, we collect precise
          location data about the trip from the Move app used by the Driver. If
          you permit the Move app to access location services through the
          permission system used by your mobile operating system (“Platform”),
          we may also collect the precise location of your device when the app
          is running in the foreground or background. We may also derive your
          approximate location from your IP address.
        </p>

        <p>
          Contacts Information: If you permit the Move app to access the address
          book on your device through the permission system used by your mobile
          platform, we may access and store names and contact information from
          your address book to facilitate social interactions through our
          Services and for other purposes described in this Statement or at the
          time of consent or collection.
        </p>

        <p>
          Transaction Information: We collect transaction details related to
          your use of our Services, including the type of service requested,
          date and time the service was provided, amount charged, distance
          traveled, and other related transaction details, duly agreed by you at
          the time of placing your booking. Additionally, if someone uses your
          promo code, we may associate your name with that person.
        </p>

        <p>
          Usage and Preference Information: We collect information about how you
          and site visitors interact with our Services, preferences expressed,
          and settings chosen. In some cases we do this through the use of
          cookies, pixel tags, and similar technologies that create and maintain
          unique identifiers.
        </p>

        <p>
          Device Information: We may collect information about your mobile
          device, including, for example, the hardware model, operating system
          and version, software and file names and versions, preferred language,
          unique device identifier, advertising identifiers, serial number,
          device motion information, and mobile network information.
        </p>

        <p>
          Call and SMS Data: Our Services facilitate and enable communications
          between Users and Drivers. In connection with facilitating this
          service, we receive call data, including the date and time of the call
          or SMS message, the parties’ phone numbers, and the content of the SMS
          message.
        </p>

        <p>
          Log Information: When you interact with the Services, we collect
          server logs, which may include information like device IP address,
          access dates and times, app features or pages viewed, app crashes and
          other system activity, type of browser, and the third-party site or
          service you were using before interacting with our Services.
        </p>

        <h4>Children</h4>
        <p>
          Move does not knowingly collect personally identifiable information
          from children and young adults under the age of eighteen. If you are
          under the age of eighteen, you must ask your parent or guardian for
          permission to use this website, download the Move Application and use
          our Services. Move is not and shall not be liable to any individual,
          for usage by a minor and/or young adult not authorized to interact
          with our Services.
        </p>

        <p>Important Information About Platform Permissions</p>
        <p>
          Most mobile platforms (iOS, Android, etc.) have defined certain types
          of device data that apps cannot access without your consent. And these
          platforms have different permission systems for obtaining your
          consent. Android devices will notify you of the permissions that the
          Move app seeks before you first use the app, and your use of the app
          constitutes your consent. Sometimes these permissions require more
          explanation than the platforms themselves provide, and the permissions
          we request will change over time.
        </p>

        <h4>Information We Collect From Other Sources</h4>
        <p>
          We may also receive information from other sources and combine that
          with information we collect through our Services. For example:
        </p>
        <p>
          If you choose to link, create, or log in to your Move account with a
          payment provider (e.g., easy paisa account) or social media service
          (e.g., Facebook), or if you engage with a separate app or website that
          uses our API (or whose API we use), we may receive information about
          you or your connections from that site or app.
        </p>
        <p>
          If your employer uses one of our enterprise solutions, if and when
          offered, we may receive information about you from your employer.
        </p>
        <p>
          If you also interact with our Services in any other capacity, we may
          combine or associate that information with information we have
          collected from you in your capacity as a User.
        </p>

        <h4>Use of Information</h4>

        <p> We may use the information we collect about you to:</p>
        <p>
          Provide, maintain, and improve our Services, including, for example,
          to facilitate payments, send receipts, provide products and services
          you request (and send related information), develop new features,
          provide customer support to Users and Drivers, develop safety
          features, authenticate users, and send product updates and
          administrative messages;
        </p>
        <p>
          Perform internal operations, including, for example, to prevent fraud
          and abuse of our Services; to troubleshoot software bugs and
          operational problems; to conduct data analysis, testing, and research;
          and to monitor and analyze usage and activity trends;
        </p>
        <p>
          Send or facilitate communications (i) between you and a Driver, such
          as estimated times of arrival (ETAs), or (ii) between you and a
          contact of yours at your direction in connection with your use of
          certain features, such as referrals, invites, or ETA sharing, if made
          available through the Move Application;
        </p>
        <p>
          Send you communications we think will be of interest to you, including
          information about products, services, promotions, news, and events of
          Move and other companies, where permissible and according to local
          applicable laws; and to process contest, sweepstake, or other
          promotion entries and fulfill any related awards;
        </p>
        <p>
          Personalize and improve the Services, including to provide or
          recommend features, content, social connections, referrals, and
          advertisements.
        </p>
        <p>
          Provide, maintain, and improve our Services, including, for example,
          to facilitate payments, send receipts, provide products and services
          you request (and send related information), develop new features,
          provide customer support to Users and Drivers, develop safety
          features, authenticate users, and send product updates and
          administrative messages;
        </p>
        <p>
          We may transfer the information described in this Statement to, and
          process and store it in Pakistan, some of which may have less
          protective data protection laws than the region in which you reside.
          Where this is the case, we will take appropriate measures to protect
          your personal information in accordance with this Statement.
        </p>
        <h4>Sharing of Information</h4>

        <p>
          We may share the information we collect about you as described in this
          Statement or as described at the time of collection or sharing,
          including as follows:
        </p>

        <h4>Through Our Services</h4>
        <p>We may share your information:</p>

        <p>
          With Drivers to enable them to fulfill their commitments expressed in
          the terms of the Services. For example, we share your name, photo (if
          you provide one), average User rating given by Drivers, and pickup
          and/or drop-off locations with Drivers;
        </p>
        <p>
          With third parties to provide you a service you requested through a
          partnership or promotional offering made by a third party or us, if
          any;
        </p>
        <p>
          With the general public if you submit content in a public forum, such
          as blog comments, social media posts, or other features of our
          Services that are viewable by the general public, if any;
        </p>
        <p>
          With third parties with whom you choose to let us share information,
          for example other apps or websites that integrate with our API or
          Services, or those with an API or Service with which we integrate; and
        </p>
        <p>
          With your employer (or similar entity) and any necessary third parties
          engaged by us or your employer (e.g., an expense management service
          provider), if you participate in any of our enterprise solutions, if
          any.
        </p>
        <h4>Other Important Sharing</h4>
        <h4>We may share your information:</h4>
        <p>
          With Move subsidiaries and affiliated entities that provide services
          or conduct data processing on our behalf, or for data centralization
          and / or logistics purposes;
        </p>
        <p>
          With vendors, consultants, marketing partners, and other service
          providers who need access to such information to carry out work on our
          behalf;
        </p>
        <p>
          In response to a request for information by a competent authority if
          we believe disclosure is in accordance with, or is otherwise required
          by, any applicable law, regulation, or legal process;
        </p>
        <p>
          With law enforcement officials, government authorities, or other third
          parties if we believe your actions are inconsistent with terms of
          Services, policies, or such other guidelines and/or decisions of Move
          to protect the rights, property, or safety of Move or others;
        </p>
        <p>
          In connection with, or during negotiations of, any merger, sale of
          company assets, consolidation or restructuring, financing, or
          acquisition of all or a portion of our business by or into another
          company;
        </p>
        <p>If we otherwise notify you and you consent to the sharing; and</p>
        <p>
          In an aggregated and/or anonymized form which cannot reasonably be
          used to identify you.
        </p>
        <h4>Social Sharing Features</h4>
        <p>
          The Services may integrate with social sharing features and other
          related tools which let you share actions you take on our Services
          with other apps, sites, or media, and vice versa. Your use of such
          features enables the sharing of information with your friends or the
          public, depending on the settings you establish with the social
          sharing service. Please refer to the privacy policies of those social
          sharing services for more information about how they handle the data
          you provide to or share through them.
        </p>
        <p>Analytics and Advertising Services Provided by Others</p>
        <p>
          We may allow others to provide audience measurement and analytics
          services for us, to serve advertisements on our behalf across the
          Internet and/or on other platforms, and to track and report on the
          performance of those advertisements. These entities may use cookies,
          web beacons, SDKs, and other technologies to identify your device when
          you visit our site and use our Services, as well as when you visit
          other online sites and services. In order to give you the best app
          experience in town, we may contact you if you faced any issues with
          the app.
        </p>
        <h4>Your Choices</h4>
        <p>Account Information</p>
        <p>
          You may correct your account information at any time by logging into
          your online or in-app account. If you wish to cancel your account,
          please email us at support@letsmovecity.com. Please note that in some
          cases we may retain certain information about you as required by law,
          or for legitimate business purposes to the extent permitted by law.
          For instance, if you have a standing credit or debt on your account,
          or if we believe you have committed fraud or violated the Terms, we
          may seek to resolve the issue before deleting your information.
        </p>
        <h4>Access Rights</h4>
        <p>
          Move will comply with individual’s requests regarding access,
          correction, and/or deletion of the personal data it stores in
          accordance with applicable law and this policy.
        </p>
        <h4>Location Information</h4>
        <p>
          We request permission for our app’s collection of precise location
          from your device per the permission system used by your mobile
          operating system. If you initially permit the collection of this
          information, you can later disable it by changing the location
          settings on your mobile device. However, this will limit your ability
          to use certain features of our Services. Additionally, disabling our
          app’s collection of precise location from your device will not limit
          our ability to collect your trip location information from a Driver’s
          device nor our ability to derive approximate location from your IP
          address.
        </p>
        <h4>Contact Information</h4>
        <p>
          We may also seek permission for our app’s collection and syncing of
          contact information from your device per the permission system used by
          your mobile operating system. If you initially permit the collection
          of this information, iOS users can later disable it by changing the
          contacts settings on your mobile device. The Android platform does not
          provide such a setting.
        </p>
        <h4>Your Privacy Rights</h4>
        <p>
          Move does not share your personally identifiable information with
          third parties for the third parties’ direct marketing purposes unless
          you provide us with consent to do so.
        </p>
        <h4>Changes to the Statement</h4>
        <p>
          We may change this Statement from time to time. If we make significant
          changes in the way we treat your personal information, or to the
          Statement, we will provide you notice through the Services or by some
          other means, such as email. Your continued use of the Services after
          such notice constitutes your consent to the changes. We encourage you
          to periodically review the Statement for the latest information on our
          privacy practices.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
